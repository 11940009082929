import React, {useEffect, useState} from 'react'
import {
  Avatar,
  Box,
  Hidden,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Container,
  makeStyles,
  Typography, Grid,
  Toolbar,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  List,
  ListItemIcon,
  ListItemAvatar,
  ListItem,

  ListItemText,
  Menu,
  MenuItem,

  Tab,
  Tabs,

  InputBase,
  fade,


  Paper,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination, TextField,
} from "@material-ui/core"
import {
  Alert,
  AlertTitle,
  Skeleton
} from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import AdminAppBar from "./admin_app_bar"
import { ElementOrLoader } from "../../../util"
import Util from "../../../../lib/util"

import { apiGet, apiPost } from "../../../../lib/api"
import { useDispatch, useSelector } from "react-redux"
import { FRONTEND_URL, REGISTRATION_KEYS, USER_TYPE } from "../../../../lib/constants"
import HomeIcon from "@material-ui/icons/Home"
import SharedStyles from '../../../shared_styles'
import clsx from 'clsx'
import {setNetworkInfo} from "./admin_slice"
import {setSnackbar} from "../system/system_slice"

const ROLE_LABELS =['', 'Super User', 'Owner', 'Admin', 'Member']
const USER_TYPE_LABELS = ['', 'Professional', 'Student']
const USER_PHASE_LABELS = ['', 'Prospect', 'Waitlist', 'App', 'Lead', 'Onboard']

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  pageContainer: {
    paddingTop: '70px',
    marginBottom: '20px'

  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey['500']
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },

  tableContainer: {
    maxHeight: '500px'
  }
}));

const LOGO = 'logo';
const NAME = 'name';
const BLURB = 'blurb';
const HANDLE = 'handle';
const PASSPHRASE = 'passphrase';
const SIGNUP_MESSAGE = 'signup_message';
const CG_URL = 'cg_url';
const NORMAL_HOME_PAGE_MESSAGE = 'normal_home_page_message';
const STUDENT_HOME_PAGE_MESSAGE = 'student_home_page_message';



const AdminDashboardSettings = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();

  const {derivedNetworkInfo} = useSelector(state => state.admin);
  const {networkInfo} = useSelector(state => state.system);

  const [logo, setLogo] = useState('');
  const [name, setName] = useState('');
  const [blurb, setBlurb] = useState('');
  const [handle, setHandle] = useState('');
  const [passphrase, setPassphrase] = useState('');
  const [signupMessage, setSignupMessage] = useState('');
  const [normalHomePageMessage, setNormalHomePageMessage] = useState('');
  const [studentHomePageMessage, setStudentHomePageMessage] = useState('');
  const [cgUrl, setCGUrl] = useState('');

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!networkInfo.Key) return;
    setLogo(networkInfo.Logo);
    setName(networkInfo.Name);
    setBlurb(networkInfo.Blurb);
    setHandle(networkInfo.Handle);
    setPassphrase(networkInfo.Passphrase);
    setSignupMessage(networkInfo.SignupMessage);
    setCGUrl(networkInfo.CommunityGuidelinesUrl);
    setNormalHomePageMessage(networkInfo.NormalHomePageMessage);
    setStudentHomePageMessage(networkInfo.StudentHomePageMessage);

  }, [networkInfo])

  const handleTextField = (event) => {
    switch(event.target.name) {
      case LOGO:
        return setLogo(event.target.value);
      case NAME:
        return setName(event.target.value);
      case BLURB:
        return setBlurb(event.target.value);
      case HANDLE:
        return setHandle(event.target.value);
      case PASSPHRASE:
        return setPassphrase(event.target.value);
      case SIGNUP_MESSAGE:
        return setSignupMessage(event.target.value);
      case CG_URL:
        return setCGUrl(event.target.value);
      case NORMAL_HOME_PAGE_MESSAGE:
        return setNormalHomePageMessage(event.target.value);
      case STUDENT_HOME_PAGE_MESSAGE:
        return setStudentHomePageMessage(event.target.value);
    }
  }

  const saveSettings = () => {
    const data = {
      logo: logo,
      name: name,
      blurb: blurb,
      passphrase: passphrase,
      signup_message: signupMessage,
      cg_url: cgUrl,
      normal_home_page_message: normalHomePageMessage,
      student_home_page_message: studentHomePageMessage
    };
    setSaving(true);
    apiPost('admin/save-settings', data)
      .then((response) => {
        if (response.error) {
          return dispatch(setSnackbar({
            open: true,
            text: response.error_message
          }))
        }
        dispatch(setNetworkInfo(response.config));
        dispatch(setSnackbar({
          open: true,
          text:'Saved. Your changes are now visible to your members'
        }))
        setSaving(false);
      })
      .catch(()=> {
        dispatch(setSnackbar({
          open: true,
          text: 'There may be something wrong with your internet. Please check and try again.'
        }))
      })
  }

  return (
    <React.Fragment>
      <AdminAppBar title="Settings" />
      <Container maxWidth="md" className={classes.pageContainer}>
        <img src={logo} style={{width: '200px', maxHeight: '150px'}}/>
        <List>
          <ListItem>
            <TextField
              name={LOGO}
              label="Logo URL"
              variant="outlined"
              fullWidth
              required
              value={logo}
              onChange={handleTextField}
              margin="dense"
            />
          </ListItem>
          <ListItem>
            <TextField
              name={NAME}
              label="Name"
              variant="outlined"
              fullWidth
              required
              value={name}
              onChange={handleTextField}
              margin="dense"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <TextField
                  name={BLURB}
                  label="Blurb"
                  variant="outlined"
                  fullWidth
                  required
                  value={blurb}
                  onChange={handleTextField}
                  margin="dense"
                />

              }
              secondary="This shows up when a short description is needed. An example is on your initial join page found in Members."/>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <TextField
                  name={HANDLE}
                  label="Join Link"
                  variant="outlined"
                  fullWidth
                  required
                  value={handle}
                  onChange={handleTextField}
                  margin="dense"
                  disabled
                />
              } secondary={`${FRONTEND_URL}/join/${handle}`} />
          </ListItem>
          <ListItem>
            <TextField
              name={PASSPHRASE}
              label="Passphrase"
              variant="outlined"
              fullWidth
              required
              value={passphrase}
              onChange={handleTextField}
              margin="dense"
            />
          </ListItem>
          <ListItem>
            <TextField
              name={SIGNUP_MESSAGE}
              label="Initial Signup Message"
              variant="outlined"
              fullWidth
              required
              value={signupMessage}
              onChange={handleTextField}
              margin="dense"
              multiline
            />
          </ListItem>
          <ListItem>
            <TextField
              name={CG_URL}
              label="Community Guidelines URL"
              variant="outlined"
              fullWidth
              required
              value={cgUrl}
              onChange={handleTextField}
              margin="dense"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <TextField
                  name={NORMAL_HOME_PAGE_MESSAGE}
                  label={`${derivedNetworkInfo.userTypeLabels[0]} Home Page Message`}
                  variant="outlined"
                  fullWidth
                  required
                  value={normalHomePageMessage}
                  onChange={handleTextField}
                  margin="dense"
                  multiline
                />
              } secondary={`This is what ${derivedNetworkInfo.userTypeLabels[0].toLocaleLowerCase()}s see at the top of their home feed.`} />
          </ListItem>
          {derivedNetworkInfo.multipleUserTypes &&
            <ListItem>
              <ListItemText
                primary={
                  <TextField
                    name={STUDENT_HOME_PAGE_MESSAGE}
                    label="Student Home Page Message"
                    variant="outlined"
                    fullWidth
                    required
                    value={studentHomePageMessage}
                    onChange={handleTextField}
                    margin="dense"
                    multiline
                  />
                } secondary="This is what students see at the top of their feed." />
            </ListItem>
          }
        </List>
        <Button
          variant="contained"
          color="primary"
          onClick={saveSettings}
        >
          <ElementOrLoader loading={saving}>
            Save Settings
          </ElementOrLoader>
        </Button>
      </Container>
    </React.Fragment>

  );
}

export default AdminDashboardSettings;

const sampleUser = {
  'FirstName': 'John',
  'LastName': 'Doe',
  'ProfilePic': 'https://media-exp1.licdn.com/dms/image/C5603AQFhoMRZgG9mig/profile-displayphoto-shrink_200_200/0?e=1596067200&v=beta&t=yac7NqPdt-EwqdcYvOoxK1iaXUHVsZp3d51KzBzsRFY',
  'Type': 1,
  'Phase': 1,
  'Role': 1
}
