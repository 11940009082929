import React, {useEffect, useState} from 'react'
import {
  Paper,
  Avatar,
  Box,
  Hidden,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Container,
  makeStyles,
  Typography, Grid,
  Toolbar,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  List,
  ListItemIcon,
  ListItemAvatar,
  ListItem,

  ListItemText,
  Menu,
  MenuItem,

  Tab,
  Tabs,

  InputBase,
  fade,



  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination, TextField,
} from "@material-ui/core"
import {
  Alert,
  AlertTitle,
  Skeleton
} from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import AdminAppBar from "./admin_app_bar"
import { ElementOrLoader } from "../../../util"
import Util from "../../../../lib/util"

import { apiGet, apiPost } from "../../../../lib/api"
import { useDispatch, useSelector } from "react-redux"
import { FRONTEND_URL, REGISTRATION_KEYS, USER_TYPE } from "../../../../lib/constants"
import HomeIcon from "@material-ui/icons/Home"
import SharedStyles from '../../../shared_styles'
import clsx from 'clsx'
import { setNetworkInfo, setPrevNetworkKey } from "./admin_slice"
import {setSnackbar} from "../system/system_slice"


import moment from "moment"
import {Line} from 'react-chartjs-2';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  pageContainer: {
    paddingTop: '70px',
    marginBottom: '20px'

  },
}));

const AdminDashboardStats = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {users, userStats, prevNetworkKey, networkKey} = useSelector(state => state.admin);

  const [loading, setLoading] = useState(false);
  const [weeklyCallLogData, setWeeklyCallLogData] = useState({});
  const [totalCalls, setTotalCalls] = useState(0);
  const [weeklyRequestLogData, setWeeklyRequestLogData] = useState({});
  const [totalRequests, setTotalRequests] = useState(0);

  useEffect(() => {
    if (!networkKey) return;
    if (networkKey == prevNetworkKey && weeklyCallLogData == {}) return;
    dispatch(setPrevNetworkKey(networkKey));

    setLoading(true);
    apiGet('admin/get-call-logs', {networkKey: networkKey})
      .then((response) => {
        processLogs(response.call_logs, setWeeklyCallLogData, 'Calls', (log) => log.TimeSlot);
        setTotalCalls(response.call_logs.length);
        setLoading(false);
      })
      .catch(()=>setLoading(false))

    apiGet('admin/get-requests', {networkKey: networkKey})
      .then((response) => {
        processLogs(response.requests, setWeeklyRequestLogData, 'Requests Made', (log) => log.Created);
        setTotalRequests(response.requests.length);
        setLoading(false);
      })
      .catch(()=>setLoading(false))
  }, [])

  const processLogs = (logs, setStateFn, label, getTimeFromLogObjectFn) => {
    // Each entry in the logs array is an object of {'TimeSlot': $date}.
    const year_week_counts = {}
    let firstWeek = moment();
    logs.forEach((log) => {
      const m = moment(getTimeFromLogObjectFn(log));
      if (m < firstWeek) {
        firstWeek = m;
      }
      const key = m.format('YYYY.W');
      if (!year_week_counts[key]) {
        year_week_counts[key] = 0;
      }
      year_week_counts[key] += 1;
    })

    // Fill in missing weeks up to 1 week in the future.
    while(firstWeek < moment().add(2, 'week')) {
      let key = firstWeek.format('YYYY.W');
      year_week_counts[key] = year_week_counts[key] || 0;
      firstWeek.add(1, 'week');
    }

    // Sort the keys (dates). Call_logs comes unsorted so the keys are most likely unsorted too.
    const sorted_year_week_counts = {};
    Object.keys(year_week_counts).sort().forEach((key) => {
      sorted_year_week_counts[key] = year_week_counts[key];
    })

    const chart_data = {
      labels:Object.keys(sorted_year_week_counts).map((key) => {
        const keyParts = key.split('.');
        return moment().year(keyParts[0]).week(keyParts[1]).day('Sunday').format('MM/D/YY')
      }),
      datasets: [{
        label: label,
        fill: false,
        borderColor: '#2ecc71',
        lineTension: 0.2,
        borderJoinStyle: 'miter',
        data: Object.values(sorted_year_week_counts),
      }]
    };
    setStateFn(chart_data);
  }

  return (
    <React.Fragment>
      <AdminAppBar title="Stats" />
      <Container maxWidth="md" className={classes.pageContainer}>
        <ElementOrLoader loading={loading}>
          <Box>
            <Typography component="span" variant="h6">Total Requests: {totalRequests}</Typography>
          </Box>
          <Line
            height={75}
            data={weeklyRequestLogData} />
          <Box>
            <Typography component="span" variant="h6">Total Calls: {totalCalls}</Typography>
          </Box>
          <Line
            height={75}
            data={weeklyCallLogData} />
        </ElementOrLoader>
      </Container>
    </React.Fragment>
  )
}

export default AdminDashboardStats;
