import React, {useEffect, useState} from 'react'
import {
  Avatar,
  Box,
  Hidden,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Container,
  makeStyles,
  Typography, Grid,
  Toolbar,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,

  Tab,
  Tabs, ThemeProvider,
} from "@material-ui/core"
import theme from "../../../../lib/theme"
import { Provider } from "react-redux"
import ReduxStore from "../../store"
import hasParams from "../../../has_params"
import { createEnum } from "../../../../lib/constants"
import { navigate } from "@reach/router"
import {useSelector, useDispatch} from "react-redux"
import { apiGet } from "../../../../lib/api"
import {processNetworkInfo, setNetworkKey} from "./admin_slice"
import {setSystemData} from "../system/system_slice"


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '250px'
  },
  pageContainer: {
    flexGrow: 1
  }
}));

const ITEM_KEYS = createEnum(['STATS', 'MEMBERS', 'TOPICS', 'SETTINGS'], 0);
const MENU_ITEMS = {
  [ITEM_KEYS.STATS] : {
    label: 'Stats',
    role: 3,
    link: 'stats'
  },
  [ITEM_KEYS.MEMBERS] : {
    label: 'Members',
    role: 3,
    link: 'members'
  },
  [ITEM_KEYS.TOPICS] : {
    label: 'Topics',
    role: 3,
    link: 'topics'
  },
  [ITEM_KEYS.SETTINGS]: {
    label: 'Settings',
    role: 3,
    link: 'settings'
  }
}


const AdminDashboard = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {networkInfo} = useSelector(state => state.system);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(()=>{
    dispatch(setNetworkKey(props.networkKey));
    // check if this user has access to this network
    apiGet('admin/is-admin', {networkKey:props.networkKey})
      .then((response)=>{
        if (response.error) {
          alert(`Access Denied`);
          return navigate('/gwx_admin');
        }
        dispatch(setSystemData({key:'networkInfo',  value:response.config}))
        dispatch(processNetworkInfo(response.config));
      })


    switch(props['*']) {
      case 'members':
        return setSelectedTab(ITEM_KEYS.MEMBERS);
      case 'topics':
        return setSelectedTab(ITEM_KEYS.TOPICS);
      case 'settings':
        return setSelectedTab(ITEM_KEYS.SETTINGS);
    }
  }, [])


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    return navigate(`/gwx_admin/${props.networkKey}/${MENU_ITEMS[newValue].link}`);
  }

  const getMenuItems = () => {
    let options = [];
    Object.keys(MENU_ITEMS).forEach((key) => {

      // Overrides.
      if (Number(key) == ITEM_KEYS.TOPICS && networkInfo.SkipTopics) return;

      const menu_item = MENU_ITEMS[key];
      options.push(
        <Tab key={key} label={menu_item.label} value={Number(key)} />
      );
    })
    return (
      <Tabs
        className={classes.tabs}
        orientation="vertical"
        variant="scrollable"
        value={selectedTab || ITEM_KEYS.STATS}
        onChange={handleTabChange}
      >
        {options}
      </Tabs>
    )
  }

  return (
    <Box className={classes.root}>
      {getMenuItems()}
      <Box className={classes.pageContainer}>
        {props.children}
      </Box>
    </Box>

  );
}

export default AdminDashboard;

