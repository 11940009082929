import React, {useEffect, useState} from 'react'
import {
  Avatar,
  Box,
  Hidden,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Container,
  makeStyles,
  Typography, Grid,
  Toolbar,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  ListItemIcon,
  ListItemAvatar,
  ListItem,

  ListItemText,
  Menu,
  MenuItem,

  Tab,
  Tabs,

  InputBase,
  fade,


  Paper,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination, Collapse, List,
} from "@material-ui/core"
import {
  Alert,
  AlertTitle,
  Skeleton
} from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import AdminAppBar from "./admin_app_bar"
import { ElementOrLoader } from "../../../util"
import Util from "../../../../lib/util"

import {apiGet} from "../../../../lib/api"
import { useSelector, useDispatch } from "react-redux"
import { API_ADDRESS, FRONTEND_URL, GREENWOODX_NETWORK_KEY, USER_PHASE, USER_TYPE } from "../../../../lib/constants"
import HomeIcon from "@material-ui/icons/Home"
import SharedStyles from '../../../shared_styles'
import clsx from 'clsx'
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import axios from "axios"
import {setTopics, setPrevNetworkKey} from "./admin_slice"


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '400px',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  pageContainer: {
    paddingTop: '70px'

  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey['500']
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },

  tableContainer: {
    maxHeight: '500px'
  }
}));

const ROLE_LABELS =['', 'Super User', 'Owner', 'Admin', 'Member']
const USER_TYPE_LABELS = ['', 'Professional', 'Student']
const USER_PHASE_LABELS = ['', 'Prospect', 'Waitlist', 'App', 'Lead', 'Onboard']


const AdminDashboardTopics = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();

  const {topics, prevNetworkKey, networkKey, derivedNetworkInfo} = useSelector(state => state.admin);
  const {networkInfo} = useSelector(state => state.system);
  const [oldNetworkKey, setOldNetworkKey] = useState(null);


  const [selectedTab, setSelectedTab] = useState(0);

  const [users, setUsers] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [loading, setLoading] = useState(false);

  const [userStats, setUserStats] = useState({});

  const [search, setSearch] = useState('');
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const [sortedRows, setSortedRows] = useState([])
  const [expanded, setExpanded] = useState('');
  const [userView, setUserView] = useState('');




  useEffect(() => {
    if (!networkKey) return;
    if (networkKey == prevNetworkKey && users.length) return;
    dispatch(setPrevNetworkKey(networkKey));

    setLoading(true);
    apiGet('admin/get-topics', {networkKey: networkKey})
      .then((response) => {
        const t = response.data;
        const tK = Object.keys(t);

        dispatch(setTopics(tK.map((key) => t[key]).sort((a, b) => Object.keys(b.n).length - Object.keys(a.n).length)));
        setLoading(false);
      });
  }, [networkInfo])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  }

  const openRowMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  }


  const getTableHeadCells = () => {
    return (
      <React.Fragment>
        <TableCell />
        <TableCell className={styles.boldText}>Label</TableCell>
        {derivedNetworkInfo.userTypeLabels.map((label) =>
          <TableCell className={styles.boldText}>{label}s</TableCell>
        )}
      </React.Fragment>
    )
  };


  const handleExpandedChange = (code) => {
    setExpanded(expanded==code? '' : code);
  };

  const handleUserView = (code) => {
    setUserView(userView==code? '' : code);
  };

  const getUserTable = (userMap) => {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={styles.boldText}>Name</TableCell>
            {derivedNetworkInfo.multipleUserTypes &&
              <TableCell className={styles.boldText}>Type</TableCell>
            }
            <TableCell className={styles.boldText}>Stage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(userMap).map((key) => (
            <TableRow key={key}>
              <TableCell>
                <ListItem>
                  <ListItemAvatar>
                    <Util.UserAvatar user={userMap[key]}/>
                  </ListItemAvatar>
                  <ListItemText
                    primary={Util.getUserFullName(userMap[key])}
                    secondary={userMap[key].Email} />
                </ListItem>
              </TableCell>
              {derivedNetworkInfo.multipleUserTypes &&
                <TableCell>{USER_TYPE_LABELS[userMap[key].Type]}</TableCell>
              }
              <TableCell>{USER_PHASE_LABELS[userMap[key].Phase]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  const TopicTable = ({topic}) => {

    const nKeys = Object.keys(topic.n);
    const sKeys = Object.keys(topic.s);
    return (
      <React.Fragment>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => handleExpandedChange(topic.topic)}>
              {expanded == topic.topic ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{topic.name}</TableCell>
          <TableCell>{Object.keys(topic.n).length}</TableCell>
          {derivedNetworkInfo.multipleUserTypes &&
            <TableCell>{Object.keys(topic.s).length}</TableCell>
          }
        </TableRow>
        <TableRow>
          <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={expanded == topic.topic} timeout="auto" unmountOnExit>
              {!derivedNetworkInfo.multipleUserTypes && getUserTable(topic.n) }
              {derivedNetworkInfo.multipleUserTypes &&
                <List>
                  <ListItem
                    button
                    onClick={() => setUserView(userView == 'n' ? '' : 'n')}
                    disabled={!nKeys.length}
                  >
                    <ListItemText primary="Professional"/>
                    {userView == 'n' ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                  </ListItem>
                  <Collapse in={userView == 'n'} timeout="auto" unmountOnExit>
                    {getUserTable(topic.n)}
                  </Collapse>
                  <ListItem
                    button
                    onClick={() => setUserView(userView == 's' ? '' : 's')}
                    disabled={!sKeys.length}
                  >
                    <ListItemText primary="Students"/>
                    {userView == 's' ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                  </ListItem>
                  <ListItem>
                    <Collapse in={userView == 's'} timeout="auto" unmountOnExit>
                      {getUserTable(topic.s)}
                    </Collapse>
                  </ListItem>
                </List>
              }
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AdminAppBar title="Topics" />
      <Container maxWidth="md" className={classes.pageContainer}>
        <Paper>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {getTableHeadCells()}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading &&
                  <TableRow key={1} >
                    <TableCell colSpan={5}>
                      <Skeleton variant="rect" style={{width:"100%", height:"74px"}}  />
                    </TableCell>
                  </TableRow>
                }
                {
                  topics.map((topic) => <TopicTable key={topic.topic} topic={topic} />)
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
    </React.Fragment>

  );
}

export default AdminDashboardTopics;

const sampleUser = {
  'FirstName': 'John',
  'LastName': 'Doe',
  'ProfilePic': 'https://media-exp1.licdn.com/dms/image/C5603AQFhoMRZgG9mig/profile-displayphoto-shrink_200_200/0?e=1596067200&v=beta&t=yac7NqPdt-EwqdcYvOoxK1iaXUHVsZp3d51KzBzsRFY',
  'Type': 1,
  'Phase': 1,
  'Role': 1
}
