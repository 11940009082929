import React, {useEffect, useState} from 'react'
import {
  Avatar,
  Box,
  Hidden,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Container,
  makeStyles,
  Typography, Grid,
  Toolbar,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  ListItemIcon,
  ListItemAvatar,
  ListItem,

  ListItemText,
  Menu,
  MenuItem,

  Tab,
  Tabs,

  InputBase,
  fade,


  Paper,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from "@material-ui/core"
import {
  Alert,
  AlertTitle,
  Skeleton
} from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import AdminAppBar from "./admin_app_bar"
import { ElementOrLoader } from "../../../util"
import Util from "../../../../lib/util"

import {apiGet} from "../../../../lib/api"
import { useSelector, useDispatch } from "react-redux"
import { FRONTEND_URL, USER_TYPE } from "../../../../lib/constants"
import HomeIcon from "@material-ui/icons/Home"
import SharedStyles from '../../../shared_styles'
import clsx from 'clsx'
import {setUsers, setPrevNetworkKey} from "./admin_slice"
import { setSnackbar } from "../system/system_slice"

const ROLE_LABELS =['', 'Super User', 'Owner', 'Admin', 'Member']
const USER_TYPE_LABELS = ['', 'Professional', 'Student']
const USER_PHASE_LABELS = ['', 'Prospect', 'Waitlist', 'App', 'Lead', 'Onboard']


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  pageContainer: {
    paddingTop: '70px'

  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey['500']
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },

  tableContainer: {
    maxHeight: '500px'
  }
}));


const AdminDashboardMembers = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();

  const {users, userStats, prevNetworkKey, networkKey, derivedNetworkInfo} = useSelector(state => state.admin);
  const {networkInfo} = useSelector(state => state.system);

  const [selectedTab, setSelectedTab] = useState(0);


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);




  useEffect(() => {
    if (!networkKey) return;
    if (networkKey == prevNetworkKey && users.length) return;
    dispatch(setPrevNetworkKey(networkKey));

    setLoading(true);
    apiGet('admin/get-users', {networkKey: networkKey})
      .then((response) => {
        dispatch(setUsers(response.users));
        setLoading(false);
      })
      .catch((error)=>console.log(error))
  }, [networkInfo])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  }

  const openRowMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  }

  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const handleCopyButton = () => {
    copyToClipboard(`${FRONTEND_URL}/join/${networkInfo.Handle}`);
    dispatch(setSnackbar({
      open: true,
      text: 'Special invite link copied!'
    }))
  }

  const NumberCaption = ({number, caption}) => {
    return (
      <Box className={styles.centerColumnElements}>
        <ElementOrLoader loading={loading}>
          <Typography style={{fontSize: '40px'}}>{number}</Typography>
        </ElementOrLoader>
        <Typography style={{fontWeight: 'bold'}}>{caption}</Typography>
      </Box>
    );
  }

  const getTableHeadCells = () => {
    return (
      <React.Fragment>
        <TableCell className={styles.boldText}>Name</TableCell>
        <TableCell className={styles.boldText}>Role</TableCell>
        {derivedNetworkInfo.multipleUserTypes &&
          <TableCell className={styles.boldText}>Type</TableCell>
        }
        <TableCell className={styles.boldText}>Stage</TableCell>
        {/*<TableCell />*/}
      </React.Fragment>
    )
  };

  const getTableRow = (user) => {
    return (
      <TableRow key={user.id}>
        <TableCell>
          <ListItem>
            <ListItemAvatar>
              <Util.UserAvatar user={user}/>
            </ListItemAvatar>
            <ListItemText
              primary={user.FirstName?Util.getUserFullName(user): 'Still onboarding'}
              secondary={user.Email || user.Phone} />
          </ListItem>
        </TableCell>
        <TableCell>{ROLE_LABELS[user.Role]}</TableCell>
        {derivedNetworkInfo.multipleUserTypes &&
          <TableCell>{USER_TYPE_LABELS[user.Type]}</TableCell>
        }
        <TableCell>{USER_PHASE_LABELS[user.Phase]}</TableCell>
        {/*<TableCell>*/}
        {/*  <IconButton*/}
        {/*    onClick={openRowMenu}*/}
        {/*  >*/}
        {/*    <MoreHorizIcon />*/}
        {/*  </IconButton>*/}
        {/*</TableCell>*/}
      </TableRow>
    )
  }

  const getTableRows = () => {
    if (loading) {
      return (
        <React.Fragment>
          <TableRow key={1} >
            <TableCell colSpan={5}>
              <Skeleton variant="rect" style={{width:"100%", height:"74px"}}  />
            </TableCell>
          </TableRow>
          <TableRow key={2} >
            <TableCell colSpan={5}>
              <Skeleton variant="rect" style={{width:"100%", height:"74px"}}  />
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    }
    if (!users.length) {
      return (
        <TableRow>
          <TableCell>You don't have any members yet.</TableCell>
        </TableRow>
      )
    }
    const visibleRows = [];
    users.filter((user)=>{
      if (!search.length) {
        return true;
      }
      const _search = search.toLowerCase();
      return (
        (user.FirstName && user.FirstName.toLowerCase().startsWith(_search)) ||
        (user.LastName && user.LastName.toLowerCase().startsWith(_search)) ||
        (Util.getUserFullName(user)).toLowerCase().startsWith(_search)
      );
    }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .forEach((user) => {
        visibleRows.push(getTableRow(user))
      });
    return visibleRows;
  }

  const getExpandNetworkAlert = () => {
    if (loading || networkInfo.ExpandNetworkEnabled) return null;
    return (
      <Alert
        className={styles.bottomMargin}
        severity="error"
        action={<Button>Turn On</Button>}
      >
        <AlertTitle>You're members are NOT able to meet new people.</AlertTitle>
      </Alert>
    );
  }

  const getRowMenu = () => {
    return (
      <Menu
        id="simple-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={()=>setMenuAnchorEl(null)}
      >
        <MenuItem>
          <ListItemText primary="Make an Admin" />
        </MenuItem>
      </Menu>
    )
  }


  return (
    <React.Fragment>
      <AdminAppBar title="Members" />
      <Container maxWidth="md" className={classes.pageContainer}>
        {getExpandNetworkAlert()}
        <Alert
          className={styles.bottomMargin}
          severity="info"
          action={
            <Button
              variant="outlined"
              onClick={handleCopyButton}
            >Copy Link</Button>
          }
        >
          <AlertTitle> Send your members this link for them to sign up </AlertTitle>
          {`${FRONTEND_URL}/join/${networkInfo.Handle}`}
        </Alert>
        <Grid container className={styles.bottomMargin}>
          <Grid item xs={4}>
            <NumberCaption number={userStats['total']} caption="Total"/>
          </Grid>
          {!derivedNetworkInfo.multipleUserTypes &&
            <Grid item xs={4}>
              <NumberCaption number={userStats[networkInfo.DefaultUserType]} caption={`Active ${derivedNetworkInfo.userTypeLabels[0]}s`} />
            </Grid>
          }
          {derivedNetworkInfo.multipleUserTypes &&
            <React.Fragment>
              <Grid item xs={4}>
                <NumberCaption number={userStats[USER_TYPE.NORMAL]} caption="Professionals"/>
              </Grid>
              <Grid item xs={4}>
                <NumberCaption number={userStats[USER_TYPE.STUDENT]} caption="Students"/>
              </Grid>
            </React.Fragment>
          }
        </Grid>
        <Box className={clsx([classes.search, styles.bottomMargin])}>
          <Box className={classes.searchIcon}>
            <SearchIcon />
          </Box>
          <InputBase
            value={search}
            onChange={(event)=>setSearch(event.target.value)}
            placeholder="Search by name or email"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            style={{width: '100%'}}
          />
        </Box>

        <Paper>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {getTableHeadCells()}
                </TableRow>
              </TableHead>
              <TableBody>
                {getTableRows()}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
      {getRowMenu()}
    </React.Fragment>

  );
}

export default AdminDashboardMembers;

const sampleUser = {
  'FirstName': 'John',
  'LastName': 'Doe',
  'ProfilePic': 'https://media-exp1.licdn.com/dms/image/C5603AQFhoMRZgG9mig/profile-displayphoto-shrink_200_200/0?e=1596067200&v=beta&t=yac7NqPdt-EwqdcYvOoxK1iaXUHVsZp3d51KzBzsRFY',
  'Type': 1,
  'Phase': 1,
  'Role': 1
}
