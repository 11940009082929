import React, {useEffect, useState} from 'react'
import { Router, navigate } from '@reach/router'
import { ThemeProvider } from "@material-ui/core"
import theme from "../lib/theme"
import { Provider, useDispatch } from "react-redux"
import ReduxStore from "../components/app/store"
import hasParams from "../components/has_params"

import AuthRoute from "../components/auth_route"
import Layout from "../components/layout"
import AdminDashboardSelector from "../components/app/features/admin/admin_dashboard_selector"
import AdminDashboard from "../components/app/features/admin/admin_dashboard"
import AdminDashboardStats from "../components/app/features/admin/admin_dashboard_stats"
import AdminDashboardMembers from "../components/app/features/admin/admin_dashboard_members"
import AdminDashboardTopics from "../components/app/features/admin/admin_dashboard_topics"
import AdminDashboardSettings from "../components/app/features/admin/admin_dashboard_settings"
import { firstLoad, apiGet } from "../lib/api"
import {setNetworkInfo} from "../components/app/features/admin/admin_slice"
import { ElementOrLoader, SnackBarWrap } from "../components/util"

const AdminDashboardWrapper = (props) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(()=> {
    setLoading(false);
    // firstLoad(dispatch)
    //   .then(()=>{
    //     // apiGet('admin/is-admin')
    //     //   .then((response) => {
    //     //     if (response.error) {
    //     //       alert(`Access Denied`);
    //     //       return navigate('/app');
    //     //     }
    //     //     setLoading(false);
    //     //     dispatch(setNetworkInfo(response.config));
    //     //   })
    //     setLoading(false);
    //   })
    //   .catch(() => {
    //     alert(`Access Denied`);
    //     return navigate('/app');
    //   })
  }, [])
  return (
    <Layout showHeader={false} showFooter={false}>
      <ElementOrLoader loading={loading}>
        <Router>
          <AuthRoute component={AdminDashboardSelector} path="/gwx_admin" />
          <AuthRoute component={AdminDashboard} path="/gwx_admin/:networkKey">
            <AuthRoute component={AdminDashboardStats} path="/stats" default />
            <AuthRoute component={AdminDashboardMembers} path="/members"  />
            <AuthRoute component={AdminDashboardTopics} path="/topics" />
            <AuthRoute component={AdminDashboardSettings} path="/settings" />
          </AuthRoute>
        </Router>
      </ElementOrLoader>
    </Layout>
  );
}

const ThemeReduxProvider = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={ReduxStore}>
        <SnackBarWrap>
          <AdminDashboardWrapper {...props} />
        </SnackBarWrap>
      </Provider>
    </ThemeProvider>
  );
}

export default hasParams(ThemeReduxProvider);
