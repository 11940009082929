import React, { useState, useEffect } from "react"
import {
  Container, CssBaseline, makeStyles, Divider,
  Box, Typography, Grid, Button, Link, TextField, Snackbar, CircularProgress, FormLabel,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper
} from "@material-ui/core"
import Layout from "../../../layout"
import SEO from "../../../seo"
import {apiGet} from "../../../../lib/api"
import {ElementOrLoader} from "../../../util"
import { navigate } from "@reach/router"
import { useDispatch, useSelector } from "react-redux"
import {setSystemData} from "../system/system_slice"
import {setUsers, setTopics, setPrevNetworkKey, clearData} from "./admin_slice"

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
}));

const AdminDashboardSelector = ({params}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [networks, setNetworks] = useState([]);

  useEffect(() => {
    dispatch(clearData());
    apiGet('admin/get-network-accesses')
      .then((response) => {
        if (response.networks.length == 0) {
          alert(`Access Denied`);
          return navigate('/app');
        }
        // If user only has access to a single network, skip the selector.
        if (response.networks.length == 1) {
          handleChooseNetwork(response.networks[0]);
        }
        setNetworks(response.networks);
        setLoading(false);
      })
      .finally(()=>setLoading(false));
  }, [])


  const handleChooseNetwork = (network) => {
    navigate(`/gwx_admin/${network.Key}/stats`);
  }

  const getNetworkSelect = () => {
    let list = [];
    list = networks.map((network, index) =>
      <ListItem
        style={{borderBottom:'1px solid #f0f0f0'}}
        button
        onClick={()=>handleChooseNetwork(network)}
        key={index}
      >
        <ListItemAvatar>
          <Avatar src={network.Logo} variant='rounded' imgProps={{style:{objectFit:'contain'}}} />
        </ListItemAvatar>
        <ListItemText primary={network.Name} />
      </ListItem>
    );
    return (
      <React.Fragment>
        <Typography variant="subtitle1" gutterBottom>
          Choose your network
        </Typography>
        <List>
          {list}
        </List>
      </React.Fragment>
    );
  }

  return (
    <Layout>
      <SEO title="Admin Dashboard"/>
      <Container maxWidth="xs">
        <CssBaseline />
        <Paper xs={12} className={classes.paper}>
          <Typography component="h1" variant="h5" gutterBottom>
            Admin
          </Typography>
          <ElementOrLoader loading={loading}>
            {getNetworkSelect()}
          </ElementOrLoader>
        </Paper>
      </Container>
    </Layout>
  );
}

export default AdminDashboardSelector;
