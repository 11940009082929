import React, {useState} from "react"
import {
  Avatar,
  Box,
  Hidden,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Container,
  makeStyles,
  Typography, Grid,
  Toolbar,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  ListItemIcon,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem
} from "@material-ui/core"
import Util from "../../../../lib/util"
import clsx from "clsx"
import SEO from "../../../seo"
import { ElementOrLoader } from "../../../util"


const AdminAppBar = ({title}) => {

  return (
      <Toolbar>
        <SEO title={`Admin: ${title}`} />
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
      </Toolbar>

  )
}

export default AdminAppBar;
